@charset "utf-8";
/*--------------------------------------------
  HEADER
--------------------------------------------*/
.l-header{
  $amp :&;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include mq(sm-){
    &__logo{
      padding: 1rem 0 1rem 1rem;
      a{
        display: block;
        img{
          height: 4rem;
        }
        &:hover{
          opacity: 0.6;
        }
      }
      .t-ttl{
        font-size: 1.2rem;
        margin-bottom: 0.5em;
        line-height: 1;
      }
      @include mq(w1300){
        a{
          img{
            height: 3rem;
          }
        }
      }
    }
  }
  @include mq(sm){
    &__logo{
      padding: 0.5em;
      a{
        display: block;
        img{
          height:2.4em;
        }
      }
      .t-ttl{
        font-size: 1.2rem;
        margin-bottom: 0.5em;
      }
    }
  }

  &__toggle{
    position: relative;
    z-index: 102;
    width: 5rem;
    height: 5rem;
    .so_toggle{
      position: absolute;
      background: transparent;
      width: 72%;
      height: 72%;
      padding:0;
      left:50%;
      top:50%;
      transform:translate(-50%,-50%);
      .hamburger-icon{
        background: transparent;
        top:0;
        &:before,&:after{
          background: $font_color;
          top:0;
        }
        &:before{
          transform:translateY(-4px);
        }
        &:after{
          transform:translateY(4px);
        }
      }
    }
  }
  .slideout-open & {
    &__toggle{
      background: rgba(255,255,255,0);
      .so_toggle{
        .hamburger-icon{
          &:before,&:after{
            background: #fff;
          }
          &:before{
            transform:rotate(45deg);
          }
          &:after{
            transform:rotate(-45deg);
          }
        }
      }
    }
  }


}