@charset "utf-8";
/*--------------------------------------------
  FOOTER
--------------------------------------------*/
.l-footer{
  background:#000;
  color: #fff;
  a{
    color: #fff;
  }
  .c-inner{
    padding:3em 0;
    @include mq(sm-){
      display: flex;
      justify-content: space-between;
    }
    @include mq(sm){
      text-align: center;
    }
  }
  &__map{
    padding-top: 40%;
  }
  &__lbox{
    .info{
      margin-bottom: 1em;
    }
    .link{
      display: flex;
      flex-wrap: wrap;
      max-width: 400px;
      margin-bottom: 1em;
      font-size: 1.4rem;
      li{
        line-height: 1;
        margin-right: 5em;
        &:nth-child(3n){
          margin-right: 0;
        }
        a{
          display: block;
          padding:0.5em 0;
          position: relative;
          @include mq(sm-){
            &:before{
              content: "";
              background: #fff;
              width: 0%;
              height: 3px;
              display: block;
              position: absolute;
              left: 0;
              bottom:0;
              transition-duration: 0.3s;
            }
            &:hover{
              &:before{
                width: 100%;
              }
            }
          }
        }
      }
    }
    .insta{
      a{
        display: inline-block;
        position: relative;
        border-radius:50%;
        border:2px solid #fff;
        padding: 0.6em;
        line-height: 1;
        @include mq(sm-){
          &:hover{
            opacity: 0.6;
          }
        }
      }
      img{
        max-width: 100%;
      }
    }
    @include mq(sm){
      .insta{
        margin-bottom: 2rem;
      }
    }
  }
  &__mbox{
    margin-bottom: 2rem;
    img{
      max-width: 100%;
      background: #fff;
      padding: 0 1em 0 0;
    }
  }
  &__rbox{
    .tel{
      line-height: 1;
      a{
        font-size: 4.6rem;
        font-family: $base_font_en;
        display: block;
        margin-bottom: 0.1em;
        @include mq(sm-){
          pointer-events:none;
        }
      }
      p{
        font-size: 1.2rem;
        margin-bottom: 1.6em;
      }
    }
    .c-btn01{
      &:last-child{
        margin-top: 0.6em;
      }
    }
    @include mq(sm){
      .tel{
        p{
          margin-bottom: 2em;
        }
      }
      .c-btn01{
        margin:0 auto;
      }
    }
  }
  .copy{
    text-align: center;
    font-size: 1.2rem;
    padding-bottom:3em;
  }
}