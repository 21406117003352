@charset "utf-8";
/*--------------------------------------------
  GLOBAL NAVIGATION
--------------------------------------------*/
@include mq(sm-){
  .l-gnavi{
    &__inner{
      display: flex;
      align-items: center;
    }
    &__menu{
      display: flex;
      flex-wrap:nowrap;
      margin-right: 1em;
      >li{
        &:nth-child(2){
          position: relative;
          &:hover{
            .l-gnavi__drop{
              opacity: 1;
              visibility: visible;
            }
          }
        }
        &:nth-child(n+2){
          margin-left: 1.5em;
        }
        >a{
          font-size: 1.4rem;
          line-height: 1;
          position: relative;
          padding: 1em 0.2em;
          &:before{
            content: "";
            background:$main_color;
            width: 0;
            height: 3px;
            display: block;
            position: absolute;
            left: 0;
            bottom:0;
            transition-duration: 0.3s;
          }
          &:hover{
            &:before{
              width: 100%;
            }
          }
        }
      }
      @include mq(w1300){
        >li{
          &:nth-child(n+2){
            margin-left: 1em;
          }
          a{
            font-size: 1.3rem;
          }
        }
      }
    }
    &__drop{
      position: absolute;
      left: 50%;
      top: 2em;
      transform: translateX(-50%);
      background: $main_color;
      width: 160%;
      opacity: 0;
      visibility: hidden;
      transition-duration: 0.3s;
      z-index: 10;
      li{
        a{
          display: block;
          padding: 1em;
          color: #fff;
          font-size: 0.9em;
          border:1px solid $main_color;
          &:hover{
            background:#fff;
            color: $main_color;
          }
        }
      }
    }
    &__inquiry{
      display: flex;
      align-items: center;
      .tel{
        line-height: 1;
        margin-right: 1em;
        @include mq(sm-){
          a{
            pointer-events:none;
          }
        }
        p{
          display: block;
          font-size: 3.3rem;
          color: $main_color;
          font-family: $base_font_en;
        }
        span{
          display: block;
          font-size: 1.2rem;
        }
      }
      .reserve{
        a{
          color: #fff;
          background: #000;
          display: block;
          height: 100%;
          font-size: 1.4rem;
          padding: 1em;
          border:1px solid $font_color;
          span{
            position: relative;
            &:before{
              content: "";
              background: url('/assets/img/common/icon_reserve-wh.png') no-repeat;
              width: 29px;
              height: 29px;
              display: block;
              margin: 0 auto 0.5em;
              transition-duration: 0.3s;
            }
          }
          &:hover{
            background: #fff;
            color: $font_color;
            span{
              &:before{
                background: url('/assets/img/common/icon_reserve-bk.png') no-repeat;
              }
            }
          }
        }
      }
    }
  }
}
.l-gnavi{

  @include mq(sm-){
    // slideout 打ち消し
    position: static;
    background: transparent;
    width: auto;
    overflow: initial;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    line-height: 1;
  }
  @include mq(sm){
    background: rgba(0,0,0,.8);
    padding: 8rem 3rem 5rem;
    right: 0;
    transform:translateX(100%);
    width: 38%;
    &__inner{
      max-width: 100%;
    }
    &__menu{
      display: block;
      li{
        line-height: 1;
        border-bottom:2px dotted #fff;
        a{
          display: block;
          color: #fff;
          padding:0.8em 0;
        }
      }
    }
    &__drop{
      margin-left: 1em;
      li{
        position: relative;
        padding-left: 1em;
        &:before{
          content: "";
          background: #fff;
          display: block;
          width: 8px;
          height: 2px;
          position: absolute;
          left: 0;
          top:50%;
        }
        &:last-child{
          border:none;
        }
      }
    }
    &__submenu{
      margin-top: 1em;
      li{
        position: relative;
        padding-left:1em;
        &:before{
          content: "";
          background: #fff;
          display: block;
          width: 8px;
          height: 2px;
          position: absolute;
          left: 0;
          top:50%;
        }
      }
      a{
        color: #fff;
        font-size: 1.4rem;
      }
    }
    &__inquiry{
      margin-top: 1em;
      .tel{
        color: #fff;
        a{
          font-family: $base_font_en;
          font-size: 2.6em;
          color: #fff;
          line-height: 1;
        }
        span{
          line-height: 1;
          display: block;
          font-size: 1.4rem;
          margin-top: 0.5em;
        }
      }
      .reserve{
        margin-top: 1em;
        a{
          background: #fff;
          display: block;
          width: 100%;
          text-align: center;
          padding: 0.5em;
        }
      }
    }
    .slideout-open &{
      transform:translateX(0);
    }
  }
  @include mq(xs){
    width: 80%;

  }
}