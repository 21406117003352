@charset "utf-8";
/*============================================

  SERIVECE

============================================*/

/*-------------------------------
  ex keeper
-------------------------------*/

.ex{
  .c-service_product{
    padding:$margin_ll 0;
    @include mq(sm){
      padding: $margin_lg 0;
    }
    @include mq(xs){
      padding: $margin_md 0;
    }
  }
  .c-service_about{
    padding-top:0;
    &__read{
      img{
        max-width: 100%;
      }
      .ex_logo{
        font-size: 3rem;
        color: $main_color;
        font-weight: 600;
        margin-bottom: 1em;
      }
      p{
        margin-bottom: 3em;
      }
      @include mq(xs){
        .ex_logo{
          font-size: 2rem;
          span{
            display: block;
            margin-bottom: 0.5em;
          }
        }
        p{
          margin-bottom: 1em;
        }
      }
    }
  }
  .c-service_point{
    &__main{
      .num{
        position: absolute;
        a{
          border-radius: 0%;
          background: #BD061B;
          padding: 0.1em 0.4em;
        }
        &.num01{
          left: 53.3%;
          top: 17%;
        }
        &.num02{
          left: 77%;
          bottom: 32%;
        }
        &.num03{
          left: 90%;
          bottom: 58%;
        }
        &.num04{
          left: 3.5%;
          top: 28%;
        }
        &.num05{
          left: 53%;
          top: 2%;
        }
        &.num06{
          left: 53%;
          top: 62%;
        }
        &.num07{
          left: 84%;
          top: 32%;
        }
        &.num08{
          left: 9%;
          top: 15%;
        }
        &.num09{
          left: 15%;
          top: 22%;
        }
        &.num10{
          left: 20%;
          top: 10%;
        }
      }
      @include mq(xs){
        .num{
          a{
            font-size: 2rem;
            padding: 0em 0.4em;
          }
        }
      }
    }
    &__list{
      .point{
        justify-content: space-between;
        li{
          @include mq(xs-){
            width: 18.5%;
            margin:0;
            &:nth-child(n+6){
              margin-top: 1em;
            }
          }
          em{
            font-style: initial;
            font-family: $base_font_en;
            font-size: 1.8em;
            position: absolute;
            left: 0;
            top: -0.2em;
          }
          span{
            line-height: 1.2;
            padding-left: 2em;
            position: relative;
            margin-bottom: 0.4em;
          }
        }
      }
      @include mq(xs){
        .point{
          li{
            em{
              top: -0.1em;
              font-size: 1.4em;
            }
            span{
              font-size: 1.6rem;
              padding-left: 1.8em;
            }
          }
        }
      }
    }
  }
}

/*-------------------------------
  faq
-------------------------------*/
.p-faq_list{
  &__box{
    margin-bottom: $margin_ll;
    @include mq(sm){
      margin-bottom: $margin_lg;
    }
    @include mq(xs){
      margin-bottom: $margin_sm;
    }
  }
  &__qestion{
    font-size: 2.6rem;
    background: $main_color;
    color: #fff;
    padding:1em;
    cursor: pointer;
    position: relative;
    padding-left:2.8em;
    line-height: 1.4;
    &:nth-child(n+2){
      margin-top: 1em;
    }
    span{
      position: absolute;
      left: 1%;
      top: 50%;
      transform: translateY(-50%);
      width: 3%;
      height: 3%;
      padding: 2%;
      border-radius: 50%;
      background: #fff;
      transition: 0.3s;
      &:before,
      &:after{
        display: block;
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 65%;
        height: 16%;
        background: #2f8acf;
      }
      &:before{
        transform: translateX(-50%) translateY(-50%);
      }
      &:after{
        transform: translateY(-50%)translateX(-50%) rotate(90deg);
      }
    }
    &.open{
      span{
        transform:translateY(-50%)  rotate(45deg);
      }
    }
    @include mq(sm){
      font-size: 2.2rem;
      line-height: 1.2;
      padding-left:2em;
    }
    @include mq(xs){
      font-size: 1.8rem;
      padding-left:1.5em
    }
  }
  &__answer{
    padding: 2em;
    .a_text{
      font-size: 2rem;
      color: $main_color;
      margin-bottom: 0.5em;
    }
    @include mq(xs){
      padding: 1.5em;
      font-size: 1.4rem;
      .a_text{
        font-size: 1.8rem;
        line-height: 1.4;
      }
    }
  }
}

/*-------------------------------
  news
-------------------------------*/
.p-news{
  &_archive{
    padding:$margin_ll 0;
    @include mq(sm){
      padding:$margin_lg 0;
    }
    @include mq(xs){
      padding:$margin_md 0;
    }
    &__inner{
      @include mq(xs-){
        display: flex;
        justify-content: space-between;
      }
    }
    &__left{
      width: 80%;
      @include mq(xs-){
        width: 75%;
      }
      @include mq(xs){
        width: 100%;
      }
    }
    &__right{
      width: 17%;
      .out{
        >li{
          .open_btn{
            display: block;
            background: $main_color;
            color: #fff;
            padding: 0.5em;
            cursor: pointer;
            position: relative;
            padding-left: 2.2em;
            span{
              position: absolute;
              left: 0.8em;
              top: 50%;
              transform: translateY(-50%);
              width: 1em;
              padding-top:1em;
              border-radius: 50%;
              background: #fff;
              transition: 0.3s;
              &:before,&:after{
                display: block;
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                width: 65%;
                height: 16%;
                background: $main_color;
              }
              &:before{
                transform: translateX(-50%) translateY(-50%);
              }
              &:after{
                transform: translateY(-50%)translateX(-50%) rotate(90deg);
              }
            }
            &.open{
              span{
                transform:translateY(-50%)  rotate(45deg);
              }
            }
          }
        }
      }
      .in{
        a{
          padding:0.5em;
          display: block;
          background: #fff;
          border-bottom:1px dashed #ddd;
          position: relative;
          &:before{
            content: "";
            background: url('/assets/img/common/icon_arrow.png') no-repeat;
            background-size: contain;
            width: 8px;
            height: 12px;
            display: block;
            position: absolute;
            right: 0.5em;
            top:50%;
            transform: translateY(-50%);
            transition-duration: 0.3s;
          }
          @include mq(sm-){
            &:hover{
              &:before{
                right: 0em;
              }
            }
          }
        }
      }
      @include mq(xs-){
        width: 23%;
        font-size: 1.4rem;
      }
      @include mq(xs){
        width: 100%;
        font-size: 1.4rem;
        .in{
          a{
            font-size: 1.4rem;
          }
        }
      }
    }
  }
  .c-news__list{
    flex-wrap: wrap;
    li{
      @include mq(sm-){
        margin-left: 0;
        margin-right: 3.5%;
        &:nth-child(3n){
          margin-right: 0;
        }
        &:nth-child(n+4){
          margin-top: 3em;
        }
      }
      span{
        font-size: 0.8em;
      }
    }
    @include mq(xs-){
      li{
        &:nth-child(n+4){
          margin-top: 3.5em;
        }
      }
    }
  }
  &_single{
    padding: $margin_lg 0;
    @include mq(sm){
      padding: $margin_md 0;
    }
    &__head{
      border-bottom:1px solid #ddd;
      padding-bottom: 1.5em;
      margin-bottom: 2.5em;
      time{
        font-family: $base_font_en;
        color: $main_color;
        font-size: 3rem;
        line-height: 1;
        display: block;
        margin-bottom: 0.3em;
      }
      .title{
        font-size: 2.6rem;
        line-height: 1.4;
      }
      @include mq(xs){
        time{
          font-size: 2.5rem;
        }
        .title{
          font-size: 2rem;
        }
      }
    }
    &__body{
      .c-btn01{
        margin-top: 2em;
      }
    }
  }
}

/*-------------------------------
  company
-------------------------------*/
.p-company_greeting{
  padding:$margin_ll 0;
  @include mq(sm){
    padding:$margin_lg 0;
  }
  @include mq(xs){
    padding:$margin_md 0;
  }
  &__box{
    p{
      margin-bottom: 1em;
      &:last-child{
        margin-bottom: 0;
      }
    }
    .name{
      font-size: 2rem;
      text-align: right;
      margin-top: 2em;
      span{
        font-size: 0.8em;
        margin-right: 0.5em;
      }
    }
  }
}

.p-company_overview{
  padding:$margin_ll 0;
  @include mq(sm){
    padding:$margin_lg 0;
  }
  @include mq(xs){
    padding:$margin_md 0;
  }
  table{
    margin-bottom: 4em;
    &:last-child{
      margin-bottom: 0;
    }
  }
}



/*-------------------------------
  shop
-------------------------------*/
.p-shop_info{
  padding:$margin_ll 0;
  @include mq(sm){
    padding:$margin_lg 0;
  }
  @include mq(xs){
    padding:$margin_md 0;
  }
  &__flx{
    @include mq(sm-){
      display: flex;
      justify-content: space-between;
    }
  }
  &__img{
    @include mq(sm-){
      width: 50%;
    }
    img{
      width: 100%;
    }
    @include mq(sm){
      margin-bottom: 1em;
    }
  }
  &__txt{
    @include mq(sm-){
      width: 50%;
      padding-left:2em;
    }
    li{
      display: flex;
      align-items: center;
      line-height: 1;
      padding:1em 0;
      line-height: 1.4;
      border-bottom:1px solid #ddd;
      span{
        width: 25%;
        position: relative;
        padding-left:0.5em;
        &:before{
          content: "";
          background: $main_color;
          width: 3px;
          height: 100%;
          display: inline-block;
          position: absolute;
          left: 0;
          top:0;
        }
      }
      p{
        width: 75%;
      }
    }
    @include mq(xs){
      font-size: 1.5rem;
      li{
        span{
          width: 25%;
        }
        p{
          width: 75%;
        }
      }
    }
  }
}

.p-shop_access{
  padding:$margin_ll 0;
  @include mq(sm){
    padding:$margin_lg 0;
  }
  @include mq(xs){
    padding:$margin_md 0;
  }
  .txt{
    margin-bottom: 1em;
  }
}

.p-shop_staff{
  padding:$margin_ll 0;
  @include mq(sm){
    padding:$margin_lg 0;
  }
  @include mq(xs){
    padding:$margin_md 0;
  }
  &__list{
    @include mq(xs-){
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
    }
    >li{
      @include mq(xs-){
        width: 28%;
        margin-right:8% ;
        &:nth-child(3n){
          margin-right: 0;
        }
      }
      @include mq(xs){
        &:nth-child(n+2){
          margin-top: 3em;
        }
      }
      .img{
        text-align: center;
        margin-bottom: 1.5em;
        img{
          max-width: 100%;
        }
      }
      .name{
        font-weight: 600;
        font-size: 2rem;
        line-height: 1;
        margin-bottom: 2em;
        text-align: center;
        @include mq(xs){
          margin-bottom: 1em;
        }
        span{
          display: inline;
          font-size: 0.8em;
        }
        p{
          display: inline;
        }
      }
    }
    .certification{
      li{
        position: relative;
        padding-left:0.8em;
        line-height: 1.3;
        &:nth-child(n+2){
          margin-top: 0.5em;
        }
        &:before{
          content: "";
          background:$main_color;
          width: 6px;
          height: 6px;
          border-radius:50%;
          display: block;
          position: absolute;
          left: 0;
          top:0.5em;
        }
      }
    }
  }
}

.p-shop_about{
  padding:$margin_ll 0;
  @include mq(sm){
    padding:$margin_lg 0;
  }
  @include mq(xs){
    padding:$margin_md 0;
  }
  &__flx{
    display: flex;
    justify-content: space-between;
  }
  &__img{
    width: 50%;
    img{
      width: 100%;
    }
  }
  &__view{
    width: 48%;
    position: relative;
    iframe{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  // @include mq(xs){
    
  //   &__img{
  //     width: 100%;
  //   }
  //   &__view{
  //     width: 100%;
  //     padding-left: 0;
  //     margin-top: 1em;
  //   }
  // }
}

/*-------------------------------
  CONTACT
-------------------------------*/
.p-reserve_calendar{
  padding:$margin_ll 0;
  @include mq(sm){
    padding:$margin_lg 0;
  }
  @include mq(xs){
    padding:$margin_md 0;
  }
}

.p-reserve_read{
  margin-bottom: 2em;
}

/*-------------------------------
  PRIVACY
-------------------------------*/
.p-privacy_list{
  padding:$margin_ll 0;
  @include mq(sm){
    padding:$margin_lg 0;
  }
  @include mq(xs){
    padding:$margin_md 0;
  }
  .read{
    margin-bottom: 2em;
  }
  .list{
    dt{
      font-size: 1.2em;
      margin-bottom: 0.5em;
    }
    dd{
      margin-bottom: 2em;
      padding-bottom:2em;
      border-bottom: 1px solid #ddd;
      span{
        display: block;
        margin-top: 1em;
      }
      a{
        color: $main_color;
        word-break: break-all;
        display: block;
        &:hover{
          text-decoration: underline;
        }
      }
    }
    .in_list{
      margin-top: 1em;
      li{
        position: relative;
        padding-left:1em;
        &:before{
          content: "";
          background: $main_color;
          width: 5px;
          height: 5px;
          display: block;
          border-radius:50%;
          position: absolute;
          left: 0;
          top:0.8em;
        }
      }
    }
  }
}

/*-------------------------------
  SITE MAP
-------------------------------*/
.p-sitemap_list{
  padding:$margin_ll 0;
  @include mq(sm){
    padding:$margin_lg 0;
  }
  @include mq(xs){
    padding:$margin_md 0;
  }
  &__inner{
    @include mq(xs-){
      display: flex;
      justify-content: space-between;
    }
  }
  &__map{
    line-height: 1;
    width: 23%;
    >li{
       @include mq(xs-){
        &:nth-child(n+2){
          margin-top: 1em;
        }
       }
      >a{
        display: block;
        padding:1em;
        border:2px solid $main_color;
        color: $main_color;
        font-weight: 600;
        @include mq(sm-){
          &:hover{
            background: $main_color;
            color: #fff;
          }
        }
      }
    }
    .in_list{
      margin-top: 1em;
      padding-left:1em;
      li{
        position: relative;
        padding-left:1em;
        a{
          font-size: 0.9em;
          display: block;
          padding: 0.3em;
          @include mq(sm-){
            &:hover{
              margin-left: 0.5em;
            }
          }
        }
        &:nth-child(n+2){
          margin-top: 1em;
        }
        &:before{
          content: "";
          background: $main_color;
          width: 10px;
          height: 3px;
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    @include mq(sm){
      font-size: 1.5rem;
    }
    @include mq(xs){
      width: 100%;
      margin-bottom: 1em;
      &:last-child{
        margin-bottom: 0;
      }
      >li{
        margin-bottom: 1em;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
}

/*-------------------------------
  THANKS
-------------------------------*/
.p-thanks{
  .thanks{
    text-align: center;
    @include mq(ss){
      text-align: center;
      font-size: 1.4rem;
    }
  }
  .c-btn01{
    margin-top: 2rem;
  }
}
/*-------------------------------
  NOT FOUND
-------------------------------*/
.p-notfound_main{
  padding: $margin_lg 0;
  @include mq(sm){
    padding: $margin_md 0;
  }
  p{
    text-align: center;
  }
  .c-btn01{
    margin-top: 2em;
  }
  @include mq(xs){
    p{
      text-align: left;
      font-size: 1.4rem;
    }
    .c-btn01{
      margin-top: 2em;
    }
  }
}