@charset "utf-8";

/*-------------------------------
  WP > EDITOR
-------------------------------*/
.wp-editor {
  +.c-btnwrap{
    margin-top: $margin_md;
  }
  strong { 
    font-weight: 700; 
  }
  em {
    font-style: italic; 
  }
  blockquote {
    display: block;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 40px;
    -webkit-margin-end: 40px;
  }
  img.alignleft {
    margin: 0 auto 0 0;
  }
  img.aligncenter {
    display: block;
    margin: 0 auto;
  }
  img.alignright {
    display: block;
    margin: 0 0 0 auto;
  }
  img[class*="wp-image-"],
  img[class*="attachment-"] {
    height: auto;
    max-width: 100%;
  }
  hr{
    margin: 2em 0;
  }
  *{
    & + p{
      margin-top: 1em;
    }
    & + ul,
    & + ol,
    & + .c-tbl_responsive {
      margin-top: 2em;
    }

    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + h5,
    & + h6 {
      margin-top: 1.5em;
    }
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: .1em;
  }
  h2{
    font-size: 1.75em;
    border-left:solid 1rem $main_color;
    padding-left: 1em;
  }
  h3{
    position: relative;
    font-size: 1.5em;
    padding-left: 1.5em;
    &:before{
      position: absolute;
      content:"◆";
      color: $main_color;
      left: 0;
      top: 0;
    }
  }
  h4{
    font-size: 1.25em;
  }
  a{
    color: $link_color;
    word-break: break-all;
    &:focus{
      opacity: .7;
    }
    @include mq(sm-){
      &:hover{
        opacity: .7;
      }
    }
  }

  ul,
  ol {
    padding-left: 2em;
    line-height: 1.5;
    > li:not(:first-child){
      margin-top: .6em;
    }
  }
  
  ul{
    > li{
      list-style-type: disc;
    }
  }
  ol{
    >li{
      list-style-type: decimal;
    }
  }
  
  table{
    tr{
      > th,
      > td{
        padding: 1em;
        border: 1px solid #ddd;
      }
      > th{
        background: $main_color;
      }
    }
  }

  @include mq(sm){
    *{
      & + p,
      & + ul,
      & + ol,
      & + .c-tbl_responsive {
        margin-top: 1.2em;
      }

      & + h1,
      & + h2,
      & + h3,
      & + h4,
      & + h5,
      & + h6 {
        margin-top: 2.5em;
      }
    }
    ul,
    ol {
      > li:not(:first-child){
        margin-top: .4em;
      }
    }
  }
  @include mq(xs){
    h2{
      font-size: 1.3em;
    }
    h3{
      font-size: 1.2em;
    }
    h4{
      font-size: 1.1em;
    }
  }
}
/*-------------------------------
  WP > PAGINATION
-------------------------------*/
.wp-pagenavi>*{
  border-radius: 0!important;
}
.wp-pagenavi{
  margin-top: $margin_md;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  a{
    background: $main_color;
    padding: 0.5em 1em!important;
    background: $main_color;
    border:1px solid  $main_color;
    color: #fff;
    @include mq(sm-){
      &:hover{
        background:#fff!important;
        color: $main_color!important;
      }   
    }
    &.previouspostslink{
      padding-left: 1em!important;
      margin-right: 0.5em!important;
      &:before,&:after{
        display: none!important;
      }
    }
    &.nextpostslink{
      padding-right: 1em!important;
      margin-left: 0.5em!important;
      &:before,&:after{
        display: none!important;
      }
    }
  }
  span{
    background: $main_color;
    padding: 0.5em 1em!important;
    background: $main_color;
    border:1px solid  $main_color;
  }
  @include mq(xs){
    .wp-pagenavi{
      font-size: 1.4rem!important;
      a{
        padding: .5em 0.6em!important;
      }
      span{
        padding: .5em 0.3em!important;
      }
    }
  }
}

