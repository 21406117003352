/*--------------------------------------------
  coating
--------------------------------------------*/
.-bg{
  background: $main_color;
  color: #fff;
  .c-ttl02{
    span{
      color: #fff;
    }
  }
  .c-ttl03{
    span{
      &:before,&:after{
        background: #fff;
      }
    }
  }
  .c-ttl04{
    background:linear-gradient(transparent 90%, #fff 90%);
  }
  table{
    th{
      border-bottom: 2px solid #fff;
    }
  }
}
.-bg_gr{
  background: $sub_color;
}
.-bg_sk{
  background: $sub_color_02;
  .c-service_menu__txt{
    .md{
      background: #fff;
    }
  }
}

.c-service{
  $amp: &;
  &_product{
    padding: $margin_ll 0;
    @include mq(sm){
      padding: 0 0 $margin_lg;
      &.-pdg{
        padding: $margin_lg 0;
      }
    }
    @include mq(xs){
      padding: 0 0 $margin_md;
    }
    &:first-child{
      padding-top:0;
    }
    .c-inner{
      #{$amp}_product{
        &__info{
          .inner{
            margin-left: 0;
          }
        }
      }
    }
    &__box{
      @include mq(xs-){
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        >div{
          width: 50%;
        }
      }
    }
    &__list{
      @include mq(xs-){
        width: 48%;
      }
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      flex-wrap: wrap;
      li{
        width: 30%;
        &:nth-child(n+4){
          margin-top: 1em;
        }
        img{
          width: 100%;
        }
        p{
          font-size: 1.3rem;
          text-align: center;
        }
      }
      @include mq(xs){
        margin-bottom: 1.5em;
      }
    }
    &__img{
      img{
        width: 100%;
      }
      @include mq(xs){
        margin-bottom: 1em;
      }
    }
    &__info{
      .inner{
        @include mq(sm-){
          max-width: 600px;
          margin-left: auto;
        }
        width: 92%;
        padding: 0 5em 0 0;
      }
      .bnr{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 1.5em;
        img{
          margin-right: 1em;
        }
        span{
          font-size: 2rem;
          background: $main_color;
          color: #fff;
          line-height: 1;
          display: inline-block;
          padding: 0.5em;
        }
      }
      h4{
        font-size: 1.9vw;
        line-height: 1.2;
        margin-bottom: 0.5em;
        @include mq(w1300){
          font-size: 3rem;
        }
      }
      p{
        line-height: 1.6;
        margin-bottom: 1em;
        text-align: left;
      }
      .annotation{
        font-size: 1.8rem;
        font-weight: 600;
        text-align: right;
        margin-top: 0.5em;
        margin-bottom: 2em;
        &:last-child{
          margin-bottom: 0;
        }
      }
      .cation{
        border:1px solid #fff;
        padding: 1em;
        margin-bottom: 1em;
        text-align: left;
      }
      .time{
        background: #fff;
        color: $main_color;
        text-align: center;
        font-weight: 600;
        font-size: 2rem;
        margin-bottom: 1em;
      }
      @include mq(sm){
        .inner{
          padding: 0 1em 0 0;
          margin-left: auto;
        }
        .bnr{
          display: block;
          img{
            margin:0 0 0.5em 0;
          }
          span{
            font-size: 1.6rem;
          }
        }
        h4{
          font-size: 2rem;
        }
      }
      @include mq(xs){
        text-align: center;
        .inner{
          padding: 0;
          margin:0  auto;
        }
        h4{
          font-size: 2.4rem;
        }
      }
    }
    &__price{
      li{
        line-height:1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom:1px solid #DDDDDD;
        padding:1em 0;
        &:first-child{
          border-top:1px solid #DDDDDD;
        }
        .ttl{
          font-size: 2.2rem;
          font-weight: 600;
        }
        .pr{
          font-size: 2.4rem;
          font-weight: 600;
          span{
            font-size: 4rem;
          }
        }
      }
      @include mq(sm){
        li{
          .ttl{
            font-size: 1.8rem;
          }
          .pr{
            font-size: 1.8rem;
            span{
              font-size: 2rem;
            }
          }
        }
      }
      @include mq(xs){

      }
    }
  }

  &_feature{
    padding: $margin_ll 0;
    @include mq(sm){
      padding: $margin_lg 0;
    }
    @include mq(xs){
      padding: $margin_md 0;
    }
    &__list{
      margin-bottom: 3em;
      &:last-child{
        margin-bottom: 0;
      }
      @include mq(xs-){
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-wrap: wrap;
      }
      >li{
        background: #fff;
        color: #000;
        width: 31.5%;
        padding: 2em;
        @include mq(xs-){
          &:nth-child(n+4){
            margin-top: 2em;
          }
        }
        .icon{
          text-align: center;
          margin-bottom: 1em;
          img{
            max-width: 100%;
          }
        }
        .ttl{
          text-align: center;
          font-size: 1.6vw;
          font-weight: 600;
          line-height:1;
          margin-bottom: 0.6em;
        }
        .txt{
          font-size: 2.6rem;
          font-weight: 600;
          color: $main_color;
          line-height: 1;
          margin-bottom: 0.4em;
          span{
            font-family: $base_font_en;
            font-size: 1.6em;
            font-weight: 400;
            margin-right: 0.2em;
          }
        }
        p{
          line-height: 1.5;;
        }
        .img{
          margin-bottom: 0.8em;
          img{
            max-width: 100%;
          }
          &.star{
            display: flex;
            justify-content: space-between;
            img{
              width: 14%;
            }
          }
        }
        .num{
          li{
            display: flex;
            align-items: flex-start;
            span{
              line-height: 1;
              background: $main_color;
              border-radius:50%;
              padding: 0.3em 0.4em;
              color: #fff;
              font-size: 1.5rem;
              display: block;
              margin-right: 0.5em;
            }
            p{
              font-size: 1.4rem;
            }
          }
          &.-eco{
            li:first-child{
              span{
                background: #328EB7;
              }
            }
            li:last-child{
              span{
                background: #3BB553;
              }
            }
          }
        }
      }
      @include mq(sm){
        >li{
          padding: 1em;
          .ttl{
            font-size: 2rem;
          }
          .txt{
            font-size: 1.8rem;
          }
          p{
            font-size: 1.4rem;
            line-height: 1.5;
          }
          .num{
            li{
              span{
                font-size: 1.3rem;
              }
              p{
                font-size: 1.3rem;
              }
            }
          }
        }
      }
      @include mq(xs){
        li{
          width:  100%;
          text-align: center;
          &:nth-child(n+2){
            margin-top: 1em;
          }
          .ttl{
            font-size: 2.6rem;
          }
          .txt{
            font-size: 2.4rem;
          }
        }
      }
    }
  }

  &_about{
    padding: $margin_ll 0 ;
    @include mq(sm){
      padding: $margin_lg 0;
    }
    @include mq(xs){
      padding: $margin_md 0;
    }
    @include mq(xs-){
      &__box{
       display: flex;
       align-items:center;
       border-bottom:1px solid #ddd;
       padding: 2em 0;
       &:last-child{
        border-bottom: none;
        padding-bottom: 0;
       }
      }
      &__img{
        width: 50%;
        text-align: center;
        img{
          max-width: 100%;
        }
      }
      &__txt{
        width: 50%;
        padding-left: 2em;
        span{
          background: #92E1FF;
        }
      }
    }
    @include mq(xs){
      &__box{
        border-bottom:1px solid #ddd;
        padding: 2em 0;
        &:last-child{
          border-bottom: none;
          padding-bottom: 0;
       }
      }
      &__img{
        margin-bottom: 1em;
        img{
          width: 100%;
        }
      }
      &__txt{
        span{
          background: #92E1FF;
        }
      }
    }
  }

  &_mechanism{
    padding: $margin_ll 0;
    @include mq(sm){
      padding: $margin_lg 0;
    }
    @include mq(xs){
      padding: $margin_md 0;
    }
    &__box{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom:1px solid #DDDDDD;
      padding: 8rem 0;
      &:nth-child(2){
        padding-top: 0;
      }
      .lbox{
        width: 42%;
        font-size: 2.6rem;
        font-weight: 600;
        line-height:1.5;
        .img{
          margin-top: 1em;
          background: $sub_color;
          padding:1em;
          text-align: center;
          img{
            max-width: 100%;
          }
          p{
            margin-top: 1em;
            font-size: 1.6rem;
            text-align: left;
            font-weight: 400;
          }
        }
      }
      .rbox{
        width:54%;
        img{
          max-width: 100%;
          margin-bottom: 1em;
        }
        span{
          display: block;
          font-size: 2.4rem;
          font-weight: 600;
          line-height: 1.4;
          margin-bottom: 0.8em;
        }
        p{
          margin-bottom: 1em;
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
      @include mq(sm){
        display: block;
        padding: 6rem 0;
        .rbox{
          width: 100%;
          margin-bottom: 1em;
          span{
            font-size: 2rem;
          }
        }
        .lbox{
          width: 100%;
          font-size: 2.2rem;
          margin-bottom: 1em;
        }
      }
      @include mq(xs){
        padding: 4rem 0;
        .lbox{
          font-size: 2rem;
        }
        .rbox{
          span{
            font-size: 1.6rem;
          }
          p{
            font-size: 1.4rem;
          }
        }
      }
    }
    &__list{
      @include mq(xs-){
        display: flex;
        justify-content: space-between;
        margin-top: 3em;
      }
      li{
        width: 24%;
        img{
          max-width: 100%;
        }
        .ttl{
          color: $main_color;
          font-size: 2rem;
          font-weight: 600;
          line-height: 1;
          margin-bottom: 1em;
          span{
            font-family: $base_font_en;
            font-size: 2em;
            font-weight: 400;
            margin-right: 0.2em;
            display: block;
            line-height: 1;
          }
        }
        p{
          font-size: 1.4rem;
          line-height: 1.6;
          margin-top: 1em;
        }
      }
      @include mq(xs){
        margin-top: 2em;
        li{
          width: 100%;
          margin-bottom: 2em;
          &:last-child{
            margin-bottom: 0;
          }
          .ttl{
            span{
              display: inline-block;
            }
          }
        }
      }
    }
  }

  &_plan{
    padding: $margin_ll 0;
    @include mq(sm){
      padding: $margin_lg 0;
    }
    @include mq(xs){
      padding: $margin_md 0;
    }
    &__read{
      display: flex;
      justify-content: space-between;
      margin-bottom: 2em;
      p{
        font-size: 2rem;
      }
      img{
        background: #fff;
        padding: 1.8em;
        max-width: 100%;
      }
      @include mq(sm){
        display: block;
        p{
          margin-bottom: 1em;
        }
      }
      @include mq(xs){
        p{
          font-size: 1.8rem;
        }
      }
    }
    &__list{
      display: flex;
      justify-content: space-between;
      li{
        width: 49%;
        background: #fff;
        color: #000;
        padding:2em;
        border:7px solid #92E1FF;
        .ttl{
          line-height: 1;
          font-size: 5rem;
          font-weight: 600;
          color: $main_color;
          margin-bottom: 0.25em;
          span{
            font-size:7.5rem;
          }
        }
        .txt{
          font-size: 2.4rem;
          font-weight: 600;
          line-height: 1;
          color: $main_color;
          margin-bottom: 0.5em;
        }
        p{
          line-height: 1.4;;
        }
      }
      @include mq(sm){
        li{
          .ttl{
            font-size: 4rem;
            span{
              font-size: 5.5rem;
            }
          }
          .txt{
            font-size: 2.2rem;
          }
        }
      }
      @include mq(xs){
        display: block;
        li{
          width: 100%;
          &:last-child{
            margin-top: 1em;
          }
          .ttl{
            font-size: 3rem;
            span{
              font-size: 4rem;
            }
          }
          .txt{
            font-size: 2rem;
          }
        }
      }
    }
    &__price{
      background: #fff;
      color: #000;
      padding: 2em;
      margin-top: 1em;
      p{
        margin-bottom: 1.5em;
        font-weight: 600;
        font-size: 2rem;
      }
    }
  }

  &_maintenance{
    padding: $margin_ll 0;
    @include mq(sm){
      padding: $margin_lg 0;
    }
    @include mq(xs){
      padding: $margin_md 0;
    }
    .c-ttl03{
      margin-bottom: 5rem;
    }
    &__box{
      display: flex;
      justify-content: space-between;
      border: 2px solid #92E1FF;
      align-items: center;
      padding:3em;
      margin-bottom: 3em;
      &:nth-child(3){
        margin-bottom: 0;
      }
      @include mq(sm){
        padding:2em;
      }
      @include mq(xs){
        display: block;
      }
    }
    &__lbox{
      line-height:1;
      text-align: center;
      font-weight: 600;
      font-size: 2rem;
      width: 15%;
      span{
        font-size: 10rem;
        display: block;
      }
      @include mq(sm){
        font-size: 1.6rem;
      }
      @include mq(xs){
        font-size: 1.8rem;
        width: 100%;
        margin-bottom: 1em;
        span{
          font-size: 6rem;
        }
      }
    }
    &__rbox{
      width: 81%;
      p{
        font-size: 2rem;
        font-weight: 600;
      }
      img{
        margin-bottom: 2em;
        max-width: 100%;
      }
      .price{
        display: flex;
        justify-content: space-between;
        li{
          width: 18.7%;
          text-align: center;
        }
        .ttl{
          background: $main_color;
          color: #fff;
          font-size: 2rem;
          font-weight: 600;
          padding: 0.2em;
        }
        .pr{
          line-height:1;
          border-bottom:2px solid $main_color;
          font-size: 2rem;
          font-weight: 600;
          padding: 1em 0;
          span{
            font-size: 2.6rem;
          }
        }
      }
      @include mq(sm){
        p{
          font-size: 1.8rem;
        }
        .price{
          li{
            width: 19%;
          }
          .ttl{
            font-size: 1.6rem;
          }
          .pr{
            font-size: 1.6rem;
            span{
              font-size: 2rem;
            }
          }
        }
      }
      @include mq(xs){
        width: 100%;
        .price{
          flex-wrap: wrap;
          li{
            width: 48%;
            &:nth-child(n+3){
              margin-top: 0.5em;
            }
          }
        }
      }
    }
    .annotation{
      margin-top: 1.5em;
      margin-bottom: 2em;
      li{
        line-height: 1.4;
        position: relative;
        padding-left: 1em;
        &:before{
          content: "※";
          position: absolute;
          left: 0;
          top:0.1em;
        }
      }
      @include mq(sm){
        font-size: 1.4rem;
        li{
          &:before{
            top:-0.1em;
          }
        }
      }
    }
  }

  &_point{
    padding: $margin_ll 0;
    @include mq(sm){
      padding: $margin_lg 0;
    }
    @include mq(xs){
      padding: $margin_md 0;
    }
    &__read{
      margin-bottom: 3em;
    }
    &__main{
      text-align: center;
      .img{
        display: inline-block;
        position: relative;
      }
      .num{
        line-height: 1;
        display: inline-block;
        font-family: $base_font_en;
        position: absolute;
        a{
          border-radius: 50%;
          background: $main_color;
          padding: 0.2em 0.5em;
          color: #fff;
          font-size: 3rem;
        }
        &.num01{
          left: 20%;
          top: 18%;
        }
        &.num02{
          left: 1%;
          bottom: 27%;
        }
        &.num03{
          left: 10%;
          bottom: 49%;
        }
        &.num04{
          left: 75%;
          top: 32%;
        }
        &.num05{
          left: 60%;
          top: 47%;
        }
      }
      img{
        max-width: 100%;
      }
    }
    &__list{
      .ttl{
        line-height: 1;
        font-family: $base_font_en;
        color: $main_color;
        font-size: 4.5rem;
        margin-bottom: 0.2em;
      }
      .point{
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        margin-bottom: 2em;
        &:last-child{
          margin-bottom: 0;
        }
        li{
          @include mq(xs-){
            width: 23%;
            &:nth-child(n+2){
              margin-left: 2%;
            }
          }
          img{
            width: 100%;
            margin-bottom: 1em;
          }
          span{
            display: block;
            line-height:1;
            color: $main_color;
            font-weight: 600;
            font-size: 2rem;
            margin-bottom: 0.3em;
          }
          p{
            line-height:1.4;
          }
        }
      }
      @include mq(sm){
        .ttl{
          line-height: 1.4;
          font-size: 4rem;
        }
        .point{
          li{
            span{
              font-size: 1.8rem;
              line-height: 1.3;
            }
            p{
              font-size: 1.4rem;
            }
          }
        }
      }
      @include mq(xs){
        .ttl{
          margin-bottom: 0em;
        }
        .point{
          justify-content: space-between;
          li{
            width: 48%;
            &:nth-child(n+3){
              margin-top: 1em;
            }
          }
        }
      }
    }
  }

  &_merit{
    padding: $margin_ll 0;
    @include mq(sm){
      padding: $margin_lg 0;
    }
    @include mq(xs){
      padding: $margin_md 0;
    }
    &__list{
      display:flex;
      justify-content: space-between;
      align-items: stretch;
      li{
        background: #fff;
        padding:2em;
        @include mq(sm-){
          width: 24%;
        }
        .num{
          font-family: $base_font_en;
          color: $main_color;
          font-size: 6rem;
          text-align: center;
          line-height: 1;
          margin-bottom: 0.1em;
        }
        span{
          font-weight: 600;
          font-size: 2.2rem;
          margin-bottom: 0.4em;
          line-height: 1.3;
          display: block;
        }
        p{
          line-height: 1.6;
        }
      }
      &.ver2{
        li{
          width: 48%;
        }
        @include mq(xs){
          display: block;
          li{
            width: 100%;
            margin-bottom: 1em;
            &:last-child{
              margin-bottom: 0;
            }
          }
        }
      }
      &.ver3{
        margin-bottom: 2em;
        &:last-child{
          margin-bottom: 0;
        }
        li{
          width: 33%;
        }
        @include mq(xs){
          display: block;
          li{
            width: 100%;
            margin-bottom: 1em;
            &:last-child{
              margin-bottom: 0;
            }
          }
        }
      }
      @include mq(sm){
        flex-wrap: wrap;
        li{
          width: 48%;
          &:nth-child(n+4){
            margin-top: 2em;
          }
        }
      }
      @include mq(xs){
        li{
          padding: 1em;
          .num{
            font-size: 4rem;
            margin-bottom: 0;
          }
          span{
            font-size: 1.8rem;
          }
          p{
            font-size: 1.4rem;
          }
        }
      }
    }
  }
  &_menu{
    padding: $margin_md 0 0;
    @include mq(xs){
      padding: 6rem 0 0;
    }
    .c-ttl03{
      margin-bottom: 7rem;
      @include mq(xs){
        margin-bottom: 5rem;
      }
    }
    &__navi{
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      max-width: 1200px;
      width: 92%;
      margin:0 auto 4em;
      li{
        margin-right: 0.5em;
        margin-bottom: 0.5em;
      }
      a{
        line-height:1;
        padding: 1em;
        display: block;
        border:2px solid $main_color;
        color: $main_color;
        font-weight: 600;
        &:hover{
          background: $main_color;
          color: #fff;
        }
      }
      @include mq(sm){
        li{
          margin-right: 0.3em;
          margin-bottom: 0.3em;
        }
        a{
          font-size: 1.4rem;
          padding: 0.8em;
        }
      }
      @include mq(xs){
        a{
          padding: 0.6em;
        }
      }
    }
    &__main{
      padding: $margin_ll 0;
      &:nth-child(3){
        padding-top: 0;
      }
      @include mq(sm){
        padding: $margin_lg 0;
      }
      @include mq(xs){
        padding: $margin_md 0;
      }
    }
    &__inner{
      @include mq(sm-){
        display: flex;
        justify-content: space-between;
      }
      margin-bottom: 2em;
      &:last-child{
        margin-bottom: 0;
      }
      @include mq(sm){
        width: 92%;
        margin: 0 auto;
      }
    }
    &__option{
      .img{
        margin-bottom: 2em;
        img{
          max-width: 100%;
        }
      }
      &_list{
        @include mq(xs-){
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 3em;
          li{
            padding: 1.5em;
            width: 32%;
            background: $main_color;
            color:#fff;
            margin-right: 2%;
            span{
              line-height:1.5;
              border-bottom: 1px solid #fff;
              padding-bottom: 1em;
              margin-bottom: 1em;
              display: block;
              font-size: 1.2em;
              font-weight: 600;
            }
            &:nth-child(3n){
              margin-right: 0;
            }
            &:nth-child(n+4){
              margin-top: 2%;
            }
          }
        }
        @include mq(sm){
          li{
            padding: 1.5em 1em;
            span{
              font-size: 1.1em;
            }
            p{
              line-height: 1.6;
            }
          }
        }
        @include mq(xs){
          margin-bottom: 2em;
          &:last-child{
            margin-bottom: 0;
          }
          li{
            background: $main_color;
            color:#fff;
            border-bottom:1px solid #fff;
            span{
              margin-bottom: 0.5em;
              display: block;
            }
          }
        }
      }
    }
    &__img{
      width: 47%;
      img{
        width: 100%;
      }
      @include mq(sm){
        width: 100%;
        margin-bottom: 2em;
      }
    }
    &__txt{
      @include mq(sm-){
        width: 50%;
        padding-right: 2em;
      }
      .inner{
        @include mq(sm-){
          max-width: 600px;
        }
      }
      .txt{
        margin-bottom: 2em;
        li{
          position: relative;
          padding-left: 1em;
          &:before{
            content: "";
            background:$main_color;
            width: 8px;
            height: 8px;
            display: block;
            position:absolute;
            left: 0;
            top:0.7em;
            border-radius:50%;
          }
        }
      }
      .lg{
        font-size: 1.8em;
        font-weight: 600;
        line-height:1.4;
        margin-bottom: 0.5em;
        color: $main_color;
      }
      .md{
        font-size: 1.4em;
        font-weight: 600;
        line-height: 1.4;
        margin-bottom: 0.8em;
        background: #cdf1ff;
        display: inline-block;
      }
      .sm{
        line-height:1.6;
      }
      .about{
        background: #fff;
        padding:2em;
        .ttl{
          color: $main_color;
          font-weight: 600;
          font-size: 1.8rem;
        }
      }
      .bnr{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1em;
        img{
          max-width: 100%;
          margin-right: 0.5em;
          margin-top: 0.5em;
        }
        a{
          display: block;
          @include mq(sm-){
            &:hover{
              opacity: 0.6;
            }
          }
        }
      }
      @include mq(sm){
        width: 100%;
        .txt{
          font-size: 1.4rem;
        }
      }
      @include mq(xs){
        .lg{
          font-size: 1.6em;
        }
        .md{
          font-size: 1.2em;
        }
      }
    }
  }
  &_column{

  }
  &__sec{
    padding: $margin_ll 0;
    @include mq(sm){
      padding: $margin_lg 0;
    }
    @include mq(xs){
      padding: $margin_md 0;
    }
  }
}

/*--------------------------------------------
  other
--------------------------------------------*/
.c-time_wrap{
  @include mq(xs-){
    display: flex;
    align-items: center;
    margin-bottom: 2em;
    .c-time{
      margin-bottom: 0;
      &:first-child{
        margin-right: 1em;
      }
    }
  }
  @include mq(xs){
    margin:2em 0;
    border-bottom:1px #ddd solid;
    padding-bottom: 2em;
    &:last-child{
      padding-bottom: 0;
      margin-bottom: 0;
      border:none;
    }
  }
}
.c-time{
  display: flex;
  align-items: center;
  margin-bottom: 2em;
  &:last-child{
    margin-bottom: 0;
  }
  .lbox{
    border:2px solid #000;
    display: inline-block;
    padding: 0.5em;
    line-height: 1;
    font-weight: 600;
    font-size: 2rem;
    margin-right: 1em;
  }
  .rbox{
    line-height:1;
    font-size: 2.4rem;
    font-weight: 600;
    span{
      font-family: $base_font_en;
      font-size: 8rem;
      color: $main_color;
      font-weight: 400;
      margin-right: 0.1em;
      vertical-align: -0.04em;
    }
  }
  @include mq(w1300){
    .rbox{
      span{
        font-size: 6rem;
      }
    }
  }
  @include mq(xs){
    margin-bottom: 1em;
    .lbox{
      font-size: 1.6rem;
    }
    .rbox{
      span{
        font-size: 5rem;
      }
    }
  }
}

.c-annotation{
  font-size: 1.4rem;
  background:$sub_color;
  padding: 1em;
  border:1px solid #ddd;
  margin-bottom: 2em;
}

.c-annotation02{
  li{
    position:relative;
    padding-left: 1em;
    font-size: 1.4rem;
    line-height:1.6;
    color: #5d5d5d;
    &:before{
      content: "※";
      position: absolute;
      left: 0;
      top:0.1em;
      display: block;
    }
  }
}

.c-price{
  margin-bottom: 3em;
  &:last-child{
    margin-bottom: 0;
  }
  &_list{
    display: flex;
    margin-bottom: 2em;
    &:last-child{
      margin-bottom: 0;
    }
    li{
      @include mq(xs-){
        width: 24%;
        text-align: center;
        margin-right: 1%;
        &:nth-child(5n){
          margin-right: 0%;
        }
      }
    }
    .size{
      background: $main_color;
      color: #fff;
      font-weight: 600;
      line-height:1;
      padding: 1em;
    }
    .price{
      font-weight: 600;
      border-bottom:1px solid $main_color;
      line-height: 1;
      padding: 1.5em 1em;
      background: #fff;
      span{
        font-size: 1.8em;
      }
    }
    &.ver3{
      li{
        @include mq(xs-){
          width: 32%;
          text-align: center;
          margin-right: 2%;
          &:nth-child(3n){
            margin-right: 0;
          }
        }
        p{
          text-align: left;
          line-height:1.5;
          margin-top: 1em;
        }
      }
    }
    @include mq(sm){
      .size{
        font-size: 1.4rem;
      }
      .price{
        padding: 1.5em 0.5em;
        span{
          font-size: 1.5em;
        }
      }
    }
    @include mq(xs){
      flex-wrap: wrap;
      li{
        width: 32%;
        margin-right: 2%;
        &:nth-child(3n){
          margin-right: 0%;
        }
        &:nth-child(n+4){
          margin-top: 4%;
        }
      }
      .size{
        padding: 1em 0;
        text-align: center;
      }
      .price{
        text-align: center;
        padding: 1em 0.5em;
        span{
          font-size: 1.3em;
        }
      }
      &.ver3{
        flex-wrap: block;
        li{
          width: 100%;
          margin-bottom: 2em;
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.c-flex_box{
  @include mq(sm-){
    display: flex;
    justify-content: space-between;
    align-items:stretch;
    >div{
      width: 48%;
      background: $sub_color_02;
      padding: 3em 2em;
      @include mq(w1300){
        padding: 3em 1em;
      }
    }
  }
  .ttl{
    line-height:1;
    text-align: center;
    font-weight: 600;
    margin-bottom: 1em;
    span{
      display: block;
      margin-bottom: 0.5em;
      color: $main_color;
      font-size: 1.8rem;
    }
    p{
      font-size: 1.8em;
    }
  }
  .c-time_wrap{
    margin-bottom: 1.5em;
  }
  .c-time{
    .lbox{
      font-size: 1.6rem;
    }
    .rbox{
      font-size: 2rem;
      span{
        font-size: 6rem;
      }
    }
  }
  @include mq(sm){
    >div{
      background: $sub_color_02;
      padding: 3em 2em;
      text-align: center;
      &:first-child{
        margin-bottom: 2em;
      }
    }
    .c-time_wrap{
      justify-content: center;
    }
  }
  @include mq(xs){
    >div{
      padding: 1.5em;
    }
    .ttl{
      p{
        font-size: 1.6em;
      }
    }
    .c-time{
      .rbox{
        span{
          font-size: 5rem;
        }
      }
    }
  }
}

.-srvs_fst{
  padding-top:0
}

/*--------------------------------------------
  flash
--------------------------------------------*/
.p-flesh_read{
  text-align: center;
  padding-bottom: 10rem;
  @include mq(xs){
    padding-bottom: 8rem;
  }
  &__head{
    font-size: 4rem;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 0.5em;
    span{
      color:$main_color;
      font-size: 1.4em;
    }
    @include mq(xs){
      font-size: 2.1rem;
    }
  } 
  &__body{
    line-height: 1;
    margin-bottom: 3em;
   p{
    font-weight: 600;
    font-size: 3rem;
    background: $main_color;
    display: inline-block;
    padding: 0.5em;
    margin-bottom: 0.8em;
    color: #fff;
   }
   em{
    font-style: initial;
    display: block;
    font-size: 2rem;
    font-weight: 600;
   }
   @include mq(xs){
    p{
      font-size: 1.6rem;
    }
    em{
      font-size: 1.4rem;
    }
   }
  }
  &__foot{
    text-align: center;
    img{
      max-width: 100%;
    }
  }
}

.p-flesh_merit{
  padding: 10rem 0;
  @include mq(xs){
    padding: 8rem 0;
  }
  &__ttl{
    font-size: 4rem;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    margin-bottom: 1.5em;
    p{
      margin-bottom: 0.5em;
    }
    em{
      font-style: initial;
      background: #fff;
      color: #2db5e8;
      font-size: 0.7em;
      padding: 0.3em;
      display: inline-block
    }
    @include mq(xs){
      font-size: 2.4rem;
      line-height: 1.3;
    }
  }
  &__list{
    @include mq(xs-){
      display: flex;
      justify-content: space-between;
      align-items: stretch; 
    }
    li{
      @include mq(xs-){
        width: 24%;
      }
      color: $font_color;
      background: #fff;
      padding: 1.5em;
      img{
        max-width: 100%;
      }
      @include mq(xs){
        &:nth-child(n+2){
          margin-top: 2em;
        }
      }
    }
  }
  &__head{
    margin-bottom: 1em;
    img{
      max-width: 100%;
    }
    @include mq(xs){
      width: 70%;
      margin: 0 auto 1em;
    }
  }
}

.p-flesh_about{
  padding: 10rem 0 0;
  @include mq(xs){
    padding: 8rem 0 0;
  }
  &__ttl{
    font-size: 4rem;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    margin-bottom: 1em;
    @include mq(xs){
      font-size: 2.6rem;
    }
  }
  &__box{
    @include mq(xs-){
      display: flex;
      justify-content: space-between;
    }
    margin-bottom: 3em;
    > div{
      @include mq(xs-){
        width: 48%;
      }
      img{
        max-width: 100%;
      }
    }
  }
  &__left{
    @include mq(xs){
      margin-bottom: 2em;
    }
  }
  &__head{
    background: $main_color;
    color: #fff;
    font-weight: 600;
    text-align: center;
    font-size: 1.2em;
    margin-bottom: 0.5em;
  }
  &__body{
    font-size: 1.4rem;
    figure{
      margin-bottom: 0.5em;
    }
    &.-flex{
      display: flex;
      justify-content: space-between;
      > div{
        width: 48%;
      }
    }
  }
  &__img{
    text-align: center;
    img{
      max-width: 100%;
    }
  }
}

.flesh_structure{
  padding: 10rem 0;
  @include mq(xs){
    padding: 8rem 0;
  }
  &__ttl{
    font-size: 4rem;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    margin-bottom: 1em;
    p{
      margin-bottom: 0.5em;
    }
    span{
      font-style: initial;
      background: #fff;
      color: $main_color;
      font-size: .7em;
      padding: 0.3em;
      display: inline-block;
    }
    @include mq(xs){
      font-size: 2.6rem;
    }
  }
  &__text{
    margin-bottom: 2em;
  }
  &__img{
    text-align: center;
    margin-bottom: 5em;
    img{
      max-width: 100%;
      border: 5px solid #fff;
    }
  }
  &__movie{
    position: relative;
    padding-bottom: 56%;
    height: 0;
    overflow: hidden;
    iframe{
      position: absolute;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.flesh_price{
  padding: 10rem 0;
  @include mq(xs){
    padding: 8rem 0;
  }
  &__ttl{
    font-size: 4rem;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    margin-bottom: 1em;
    @include mq(xs){
      font-size: 2.6rem;
    }
  }
  .c-table{
    margin-bottom: 1em;
    th{
      background: $main_color;
      border-color:#fff ;
      color: #fff;
      @include mq(xs-){
        width: 35%;
      }
    }
    td{
      text-align: center;
      font-weight: 600;
      @include mq(xs-){
        width: 30%;
      }
    }
  }
}