@charset "utf-8";
/*============================================

  INDEX

============================================*/
/*-------------------------------
  INDEX > KEY
-------------------------------*/
.p-idx_key{
  @include mq(sm-){
    display: flex;
    flex-direction: row-reverse;
    align-items: stretch;
    height: calc(100vh - 8rem);
    min-height: 60rem;
  }
  margin-bottom: 9rem;
  &__img{
    width: 62.5%;
    img{
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    @include mq(sm){
      width: 100%;
      height: 50rem;
    }
    @include mq(sm){
      height: 30rem;
    }
  }
  &__txt{
    background:$sub_color;
    width: 37.5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:baseline;
    padding-left: 3%;
    .en{
      line-height: 0.8;
      color: $main_color;
      font-family: $base_font_en;
      font-size: 9.6vw;
      margin-bottom: 0.1em;
    }
    span{
      font-size: 2.2vw;
      font-weight: 600;
      display: inline-block;
      line-height: 1;
      position: relative;
      margin-bottom: 1.4em;
      &:before{
        content: "";
        background: #000;
        height: 3px;
        width: 100%;
        display: block;
        position: absolute;
        bottom:-0.7em;
        left: 0;
      }
    }
    p{
      font-size: 1.3vw;
      font-weight: 600;
      line-height: 1.6;
    }
    @include mq(sm){
      width: 100%;
      text-align: center;
      padding: 2em 0;
      .en{
        margin:0 auto 0.2em;
        font-size: 6rem;
      }
      span{
        font-size: 2rem;
        margin:0 auto 1.5em;
      }
      p{
        font-size: 1.6rem;
        margin: auto;
      }
    }
    @include mq(xs){
      p{
        font-size: 1.4rem;
        margin: auto;
      }
    }
  }
}

/*-------------------------------
  INDEX > coating
-------------------------------*/
.p-idx_coating{
  padding-bottom:$margin_ll;
  @include mq(sm){
    padding-bottom:$margin_lg;
  }
  @include mq(xs){
    padding-bottom:$margin_md;
  }
  &__box{
    @include mq(xs-){
      display: flex;
      align-items: center;
      &:nth-child(even){
        flex-direction: row-reverse;
        .inner{
          margin-left: auto;
          @include mq(sm){
            margin-right: auto;
          }
        }
      }
      &:nth-child(odd){
        .inner{
          margin-left: 4rem;
          @include mq(sm){
            margin-left: 3rem;
          }
        }
      }
      >div{
        width: 50%;
      }
    }
  }
  &__img{
    img{
      width: 100%;
    }
  }
  &__txt{
    .inner{
      max-width: 600px;
      width: 92%;
    }
    .en{
      line-height: 1;
      font-size: 4.2rem;
      font-weight: 500;
      color: $main_color;
      font-family: $base_font_en;
      span{
        font-size: 7rem;
        padding-left: 0.1em;
      }
    }
    .ttl{
      line-height: 1;
      font-size: 5.2rem;
      font-weight: 600;
      margin-bottom: 0.4em;
    }
    .cate{
      background:$main_color;
      display: inline-block;
      line-height: 1;
      color: #fff;
      font-size: 1.8rem;
      font-weight: 600;
      padding: 0.5em 1em;
      margin-bottom: 2em;
    }
    p{
      font-size: 1.8rem;
      margin-bottom: 1.5em;
    }
    @include mq(w1300){
      .inner{
        padding: 2em 0;
      }
      .ttl{
        font-size: 4rem;
      }
    }
  }
  @include mq(sm){
    .en{
      font-size: 2.8rem;
      span{
        font-size: 4.5rem;
      }
    }
    .ttl{
      font-size: 3rem;
    }
    .cate{
      font-size: 1.6rem;
      padding: 0.5em 1em;
      margin-bottom: 1em;
    }
    p{
      font-size: 1.6rem;
      margin-bottom: 1em;
    }
  }
  @include mq(xs){
    .inner{
      margin:0 auto;
      padding: 1em 0 2em;
      text-align: center;
    }
    .c-btn01{
      margin:0 auto;
    }
  }
}

/*-------------------------------
  INDEX > point
-------------------------------*/
.p-idx_point{
  padding: $margin_ll 0;
  background:$main_color;
  @include mq(sm){
    padding:$margin_lg 0;
  }
  @include mq(xs){
    padding:$margin_md 0;
  }
  &__list{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    li{
      background: #fff;
      width: 31.5%;
      position: relative;
      text-align: center;
      padding: 3em 2.7em;
      @include mq(xs-){
        &:nth-child(n+4){
          margin-top: 3rem;
        }
      }
      .num{
        position: absolute;
        left: 0;
        top:0;
        line-height: 1;
        background: $main_color;
        color: #fff;
        font-size: 8rem;
        font-family: $base_font_en;
        padding: 0.1em 0.2em;
      }
      .icon{
        margin-bottom: 1.5em;
        img{
          max-width: 100%;
        }
      }
      .ttl{
        font-size: 1.4vw;
        line-height: 1.4;
        font-weight: 600;
        position: relative;
        display: inline-block;
        margin-bottom: 1.8em;
        letter-spacing: -0.02em;
        &:before{
          content: "";
          background: #000;
          width: 22rem;
          height: 2px;
          display: block;
          position: absolute;
          left: 50%;
          bottom:-.9em;
          transform: translateX(-50%);
        }
      }
      p{
        font-size: 1.8rem;
        line-height: 1.5;
        margin-bottom: 1em;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
    @include mq(w1300){
      li{
        padding: 2em 1.5em;
        .ttl{
          font-size: 2.4rem;
        }
      }
    }
    @include mq(sm){
      li{
        width: 32%;
        padding: 3.5em 1em 2.5em;
        .num{
          font-size: 5rem;
        }
        .ttl{
          font-size: 1.8rem;
          margin-bottom: 1.6em;
          &:before{
            width: 100%;
          }
        }
        p{
          font-size: 1.4rem;
          text-align: left;
        }
      }
    }
    @include mq(xs){
      li{
        width: 48%;
        &:nth-child(n+3){
          margin-top: 1em;
        }
        .num{
          font-size: 4rem;
        }
        .ttl{
          font-size: 1.6rem;
        }
        p{
          font-size: 1.2rem;
        }
        .icon{
          margin-bottom: 1.5em;
          height: 6rem;
          margin: 0 auto 1em;
          img{
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
        }
        .c-btn02{
          a{
            font-size: 1.4rem;
          }
        }
      }
    }
  }
}

/*-------------------------------
  INDEX > menu
-------------------------------*/
.p-idx_menu{
  padding-top:$margin_ll;
  @include mq(sm){
    padding-top:$margin_lg;
  }
  &__list{
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    li{
      width: 50%;
      color: #fff;
      position: relative;
      .c-inner{
        max-width: 87%;
      }
      &.-full{
        width: 100%;
        .c-inner{
          align-items: flex-end;
          @include mq(sm-){
            .lbox{
              margin-right: 5em;
              margin-bottom: 0;
            }
          }
          @include mq(w1300){
            display: block;
          }
        }
      }
      &:nth-child(even){
        .c-inner{
          @include mq(xs-){
            max-width: 600px;
          }
          margin-left: auto;
          margin-right: 0;
        }
      }
      img{
        width: 100%;
      }
    }
    &_img{
      @include mq(w1300){
        height: 30rem;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      @include mq(xs){
        height: 28rem;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    &_txt{
      width: 100%;
      background:rgba(0,0,0,0.4);
      padding:2.3em 0;
      position: absolute;
      left: 0;
      bottom:0;
      .lbox{
        margin-bottom: 0.7em;
        h3{
          line-height: 1;
        }
        span{
          line-height: 1;
          display: block;
          font-family: $base_font_en;
          font-size: 6rem;
        }
      }
      .c-btn01{
        max-width: 250px;
      }
      @include mq(sm){
        height: 100%;
        .c-inner{
          position: absolute;
          left: 50%;
          top:50%;
          transform: translate(-50%,-50%);
          text-align: center;
          .c-btn01{
            margin:0 auto;
          }
        }
        .lbox{
          h3{
            margin-bottom: 0.5em;
          }
          span{
            font-size: 4.5rem;
          }
        }
      }
    }
    @include mq(xs){
      li{
        width: 100%;
      }    
    }
  }
}

/*-------------------------------
  INDEX > insta
-------------------------------*/
.p-idx_insta{
  padding:$margin_ll 0;
  position: relative;
  &:before{
    content: "";
    background: $main_color;
    width: 100%;
    height: 50rem;
    position: absolute;
    left: 0;
    bottom:0;
  }
  .c-inner{
    position: relative;
  }
  @include mq(sm){
    padding:$margin_lg 0;
    &:before{
      height: 20rem;
    }
  }
  @include mq(xs){
    padding:$margin_md 0;
    &:before{
      height: 15rem;
    }
  }
}

/*-------------------------------
  INDEX > schedule
-------------------------------*/
.p-idx_schedule{
  padding: $margin_ll 0;
  &__box{
    margin-bottom: 3em;
  }
  @include mq(sm){
    padding: $margin_lg 0;
  }
  @include mq(xs){
    padding: $margin_md 0;
  }
}

/*-------------------------------
  INDEX > news
-------------------------------*/
.p-idx_news{
  padding: $margin_ll 0;
  position: relative;
  &:before{
    content: "";
    background: $main_color;
    width: 100%;
    height: 47rem;
    display: block;
    position: absolute;
    left: 0;
    top:0;
  }
  .c-inner{
    position: relative;
  }
  @include mq(sm){
    padding: $margin_lg 0;
    &:before{
      height: 30rem;
    }
  }
  @include mq(xs){
    padding: $margin_md 0;
    &:before{
      height: 20rem;
    }
  }
}