@charset "utf-8";


/*--------------------------------------------
  FRAMEWORK
--------------------------------------------*/
.c-page-top{
  position:fixed;
  bottom:0;
  right: 0;
  z-index: 99;
  @include mq(xs){
    display: flex;
    align-items:stretch;
    width: 100%;
  }
  >div{
    @include mq(xs){
      // width: calc(100% / 3);
    }
  }
  a{
    display: block;
    position: relative;
    text-align: center;
    padding:0.8em;
    transition-duration: 0.3s;
    @include mq(xs-){
      width: 5em;
      height: 5em;
    }
    @include mq(xs){
      height: 100%;
      padding:0.2em;
      display: flex;
      align-items:center;
      justify-content: center;
    }
  }
  .reserve{
    a{
      display: block;
      background:$main_color;
      color: #fff;
      border:2px solid $main_color;
      &:before{
        content: "";
        background:url('/assets/img/common/icon_reserve-wh.png') no-repeat;
        width: 29px;
        height: 29px;
        display: block;
        margin: 0 auto 0.3rem;
      }
      @include mq(sm-){
        &:hover{
          background: #fff;
          color: $main_color;
          &:before{
            background:url('/assets/img/common/icon_reserve-b.png') no-repeat;
          }
        }
      }
    }
    @include mq(xs){
      width: 25%;
    }
  }
  .tel{
    a{
      background:#fff;
      color: $main_color;
      font-size: 9vw;
      line-height: 1;
      font-family: $base_font_en;
    }
    @include mq(xs){
      width: 60%;
    }
  }
  .top{
    a{
      background: #000;
      border:2px solid #000;
      &:before{
        content: "";
        background:url('/assets/img/common/icon_arrow_w.png') no-repeat;
        width: 10px;
        height: 16px;
        display: block;
        position: absolute;
        left: 50%;
        top:50%;
        transform: translate(-50%, -50%) rotate(-90deg);
      }
      @include mq(sm-){
        &:hover{
          background:#fff;
          color: #000;
          &:before{
            background:url('/assets/img/common/icon_arrow.png') no-repeat;
          }
        }
      }
    }
    @include mq(xs){
      width: 15%;
    }
  }
}

.c-inner {
  width: $base_width;
  max-width: $base_width_max;
  margin-left: auto;
  margin-right: auto;
  &.-full {
    width: $base_width_max;
  }
  @include mq(ss){
    max-width: 90%;
  }
}

.c-fullpage{
  width: 100%;
  height: 100vh;
  // scroll-snap-type: y mandatory;
  // overflow-y: auto;
  // -webkit-overflow-scrolling: touch;
  &__sec{
    width: 100%;
    min-height: 100vh;
    // scroll-snap-align: start;
    filter: blur($margin_ss);
    transition:filter .6s .6s;
    .c-inner{
      padding:  $margin_md*2 0;
      max-width: 80%;
      min-height: 100vh;
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-direction: column;
      @include mq(ss){
        max-width: 90%;
        margin-right: 10%;
        padding: $margin_md*2 $margin_ss;
      }
    }
    &.is-show{
      filter: blur(0);
    }
  }
  &__pager{
    position: fixed;
    right: $margin_ss*2;
    top: 50%;
    transform:translateY(-50%);
    z-index: 9;
    li{
      position: relative;
      &:nth-child(n+2){
        padding-top: $margin_ss*2;
        &:before{
          position: absolute;
          content:"";
          left: .3125em;
          top: $margin_ss/4;
          width: 1px;
          height: $margin_ss*3/2;
          background: #fff;
        }
      }
      a{
        display: block;
        width: calc(.625em + 1px);
        height: calc(.625em + 1px);
        border: solid 2px #fff;
        border-radius: 50%;
      }
      &.is-act{
        a{
          background: #fff;
        }
      }
    }
  }
  @include mq(w1400){
    &__pager{
      right: $margin_ss;
    }
  }
}

/*-------------------------------
  under > key
-------------------------------*/
.p-under_key{
  position: relative;
  &__img.c-img_ofi{
    padding-top: 22%;
    width: 81%;
    margin-left: auto;
    @include mq(xs){
      width: 70%;
      padding-top: 33%;
    }
  }
  &__txt{
    position: absolute;
    left: 4%;
    top:50%;
    transform: translateY(-50%);
    line-height: 1;
    .en{
      font-size: 13rem;
      font-family: $base_font_en;
      color: $main_color;
      margin-bottom: 0.1em;
    }
    .jp{
      font-size: 3rem;
      position: relative;
      display: inline-block;
      &:before{
        content: "";
        background:#000;
        width: 100%;
        height: 1px;
        display: block;
        position: absolute;
        left: 0;
        bottom:-0.2em;
      }
    }
    @include mq(sm){
      .en{
        font-size: 7rem;
        margin-bottom: 0em;
      }
      .jp{
        font-size: 2.2rem;
      }
    }
    @include mq(xs){
      .en{
        font-size: 4rem;
      }
      .jp{
        font-size: 2rem;
        text-shadow: 0 1px 0 #fff, 1px 0 0 #fff, 0 -1px 0 #fff, -1px 0 0 #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
      }
    }
  }
}

/*--------------------------------------------
  TO TOP
--------------------------------------------*/
.totop{

}
/*--------------------------------------------
  TITLE
--------------------------------------------*/
.c-ttl01{
  text-align: center;
  line-height: 1;
  margin-bottom: 5rem;
  span{
    font-size: 2.4rem;
    font-weight: 600;
    margin-bottom: 0.3em;
    display: block;
  }
  h2{
    font-size: 9rem;
    font-family: $base_font_en;
  }
  &.-wh{
    color: #fff;
  }
  @include mq(sm){
    margin-bottom: 4rem;
    span{
      font-size: 2.2rem;
    }
    h2{
      font-size: 6rem;
    }
  }
  @include mq(xs){
    span{
      font-size: 2rem;
    }
    h2{
      font-size: 5rem;
    }
  }
}

.c-ttl02{
  line-height: 1;
  text-align:center;
  margin-bottom: 8rem;
  span{
    font-size: 4.2rem;
    font-family: $base_font_en;
    color: $main_color;
    margin-bottom: 0.2em;
    display: block;
  }
  h3{
    font-size: 4.8rem;
  }
  &.-wht{
    span,
    h3{
      color: #fff;
    }
  }
  @include mq(sm){
    margin-bottom: 6rem;
    span{
      font-size: 3.5rem;
    }
    h3{
      font-size: 3.5rem;
    }
  }
  @include mq(xs){
    margin-bottom: 4rem;
    span{
      font-size: 3.6rem;
    }
    h3{
      font-size: 2.4rem;
    }
  }
}

.c-ttl03{
  margin-bottom: 10rem;
  span{
    display: block;
    line-height: 1.3;
    font-size: 3.6rem;
    font-weight: 600;
    position: relative;
    &:before,&:after{
      content: "";
      display: block;
      position: absolute;
    }
    &:before{
      background: $main_color;
      height: 4px;
      width: 10%;
      left: 0;
      bottom:-0.8em;
    }
    &:after{
      background:#DDDDDD;
      height: 1px;
      width: 90%;
      right: 0;
      bottom:-0.75em;
    }
  }
  p{
    font-size: 2rem;
    margin-top: 5.5rem;
  }
  @include mq(sm){
    margin-bottom: 7rem;
    span{
      font-size: 2.6rem;
    }
  }
  @include mq(xs){
    margin-bottom: 5rem;
    span{
      font-size: 2.2rem;
      line-height: 1.4;
    }
    p{
      font-size: 1.8rem;
    }
  }
}

.c-ttl04{
  background:linear-gradient(transparent 75%, $main_color 75%);
  font-weight: 600;
  font-size: 2.4rem;
  display: inline-block;
  line-height: 1.3;
  margin-bottom: 1em;
  @include mq(xs){
    font-size: 2rem;
  }
}

/*--------------------------------------------
  BUTTON, LINK
--------------------------------------------*/
.c-btnwrap {
  &.-fx{
    display: flex;
    justify-content: center;
    align-items:center;
    &.-justify{
      justify-content: space-between;
    }
    &.-right{
      justify-content: flex-end;
    }
  }
  &.-center{
    text-align: center;
  }
  &.-right{
    text-align: right;
  }
}


.c-btn01{
  max-width: 300px;
  width: 100%;
  text-align: center;
  a{
    display: block;
    background: #000;
    line-height: 1;
    color: #fff;
    padding: 1.2em 1em;
    border: 1px solid #000;
    @include mq(sm-){
      &:hover{
        background:#fff;
        color: #000;
      }
    }
  }
  &.-wh{
    a{
      border: 1px solid #fff;
      background: #fff;
      color: #000;
      @include mq(sm-){
        &:hover{
          background:#000;
          color: #fff;
        }
      }
    }
  }
}

.c-btn02{
  a{
    font-size: 1.8rem;
    font-weight: 600;
    display: inline-block;
    span{
      position: relative;
      padding-right: 1em;
      &:before{
        content: "";
        background: url('/assets/img/common/icon_arrow.png') no-repeat;
        width: 10px;
        height: 16px;
        display: block;
        position: absolute;
        right: 0;
        top:50%;
        transform: translateY(-50%);
        transition-duration: 0.3s;
      }
    }
    @include mq(sm-){
      &:hover{
        color: $main_color;
        span{
          &:before{
            right: -0.5em;
          }
        }
      }
    }
  }
}



.c-clm{
  @include mq(xs-){
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    &.clm04{
      li{
        width: 23%;
        margin-right: 2.5%;
        &:nth-child(4n){
          margin-right: 0;
        }
        &:nth-child(n+5){
          margin-top: 2em;
        }
      }
    }
    &.clm03{
      li{
        width: 30%;
        margin-right: 5%;
        &:nth-child(3n){
          margin-right: 0;
        }
        &:nth-child(n+4){
          margin-top: 2em;
        }
      }
    }
    &.clm02{
      justify-content: space-between;
      li{
        width: 48%;
        &:nth-child(n+4){
          margin-top: 3em;
        }
      }
    }
  }
  li{
    margin-bottom: 4em;
    &:last-child{
      margin-bottom: 0;
    }
  }
  img{
    max-width: 100%;
    display: block;
    margin-bottom: 0.5em;
  }
  p{
    line-height: 1.5;
    margin-bottom: 0.5em;
    &:last-child{
      margin-bottom: 0;
    }
  }
}


.c-item{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2em;
  li{
    line-height: 1;
    border:2px solid $main_color;
    color: $main_color;
    padding:0.8em;
    font-weight: 600;
    margin-right: 0.5em;
    margin-top: 0.5em;
    background: #fff;
  }
}


.c-price_box{
  display: flex;
  justify-content: space-between;
  li{
    width: 18.7%;
    text-align: center;
  }
  &.colm6{
    li{
      width: 15.9%;
    }
    @include mq(sm){
      flex-wrap: wrap;
      li{
        width: 49%;
      }
    }
  }
  .ttl{
    background: $main_color;
    color: #fff;
    font-size: 2rem;
    font-weight: 600;
    padding: 0.2em;
  }
  .pr{
    line-height:1;
    border-bottom:2px solid $main_color;
    font-size: 2rem;
    font-weight: 600;
    padding: 1em 0;
    span{
      font-size: 2.6rem;
    }
  }
}

/*--------------------------------------------
  TABLE
--------------------------------------------*/
.submit_area{
  text-align: center;
  input[type=submit]{
    color: #fff;
    font-size: 2rem;
    font-weight: 600;
    transition-duration: 0.3s;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    margin: auto;
    padding:1em;
    transition-duration: 0.3s;
  }
  .btn_submit{
    max-width: 30rem;
    width: 100%;
    border:2px solid $accent_color;
    background: $accent_color;
    margin: auto;
    @include mq(sm-){
      &:hover{
        color: $accent_color;
        background: #fff;
      }
    }
  }
  .back{
    background: #777;
    max-width: 30rem;
    width: 100%;
    border:2px solid #777;
    margin: auto 0;
    @include mq(sm-){
      &:hover{
        color: #777;
        background: #fff;
      }
    }
    @include mq(xs){
      margin: 1rem auto 0!important;
    }
  }
}
.c-table{
  max-width: 1200px;
  margin: auto;
  font-size: 1.8rem;
  th,td{
    padding: 1em 2em;
  }
  th{
    border-bottom: 2px solid $main_color;
    width: 25%;
  }
  td{
    border-bottom: 1px solid #ddd;
    width: 75%;
    span{
      font-weight: 600;
      display: block;
      line-height: 1;
      margin-bottom: 0.5em;
    }
    p{
      border-bottom:1px solid #fff;
      padding-bottom:0.5em;
      margin-bottom: 0.8em;
      line-height: 1.4;
      font-size: 0.9em;
      &:last-child{
        border-bottom: none;
        margin-bottom: 0;
      }
    }
  }
  .img{
    img{
      max-width: 100%;
    }
  }
  @include mq(sm){
    font-size: 1.6rem;
    th{
      text-align: left;
      padding: 1em 0em;
      line-height: 1.3;;
    }
    td{
      padding:1em 0;
    }
  }
  @include mq(xs){
    th,td{
      display: block;
      width: 100%;
    }
    th{
      text-align: center;
      padding-bottom:0.5em;
    }
    td{
      padding-bottom: 1.8em;
    }
  }
}
.submit_area{
  text-align: center;
  input[type=submit]{
    color: #fff;
    font-size: 2rem;
    font-weight: 600;
    transition-duration: 0.3s;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    margin: auto;
    padding:1em;
    transition-duration: 0.3s;
  }
  .btn_submit{
    max-width: 30rem;
    width: 100%;
    border:2px solid $accent_color;
    background: $accent_color;
    margin: auto;
    @include mq(sm-){
      &:hover{
        color: $accent_color;
        background: #fff;
      }
    }
  }
  .back{
    background: #777;
    max-width: 30rem;
    width: 100%;
    border:2px solid #777;
    margin: auto;
    @include mq(sm-){
      &:hover{
        color: #777;
        background: #fff;
      }
    }
  }
  
  
}

.c-tbl_responsive{
  @include mq(sm){
    &__inner{
      overflow-x:auto;
      overflow-y: visible;
      table{
        min-width: 100%;
        width:auto;
        th,
        td{
          white-space: nowrap;
        }
      }
      &::-webkit-scrollbar {
        height: .6rem;
      }
      &::-webkit-scrollbar-track {
      background: #f1f1f1;
      }
      &::-webkit-scrollbar-thumb {
        background: $main_color;
      }
    }
    &:after{
      display: block;
      content:"横にスクロールできます";
      text-align: center;
      margin-top: .5em;
    }
  }
}

/*--------------------------------------------
 BLOCK - NEWS
--------------------------------------------*/
.c-news{
  &__list{
    margin-bottom: 3em;
    li{
      a{
        display: block;
        height: 100%;
        @include mq(sm-){
          .img{
            overflow: hidden;
          }
          img{
            transition-duration: 0.3s;
          }
        }
        &:hover{
          img{
            transform: scale(1.1);
          }
        }
      }
    }
    @include mq(xs-){
      display: flex;
      align-items: stretch;
    }
    li{
      width: 31%;
      position: relative;
      @include mq(xs-){
        &:nth-child(n+2){
          margin-left: 3.5%;;
        }
      }
      span{
        line-height: 1;
        display: inline-block;
        background: #000;
        color: #fff;
        padding: 0.5em 1em;
        position: absolute;
        left:0;
        top:-1em;
        z-index: 2;
      }
      p{
        padding:2.5rem 2.5rem 1rem;
        line-height: 1.5;
      }
      time{
        padding:0 2.5rem ;
        font-size: 2.4rem;
        color: $main_color;
        font-family: $base_font_en;
      }
    }
    @include mq(xs){
      li{
        width: 100%;
        &:nth-child(n+2){
          margin-top: 1.5em;
        }
        a{
          width: 100%;
          display: flex;
          align-items:center;
          justify-content: space-between;
        }
        span{
          top: 0;
        }
        .img{
          width: 45%;
          padding-top: 38%;
        }
        .txt{
          width: 50%;
        }
        p{
          font-size: 1.4rem;
          padding:0 0 0.5em 0;
        }
        time{
          padding:0;
        }
      }
    }
  }
}

/*--------------------------------------------
  c-pnavi
--------------------------------------------*/
.c-pnavi{
  padding: 8rem 0;
  max-width: 1200px;
  width: 92%;
  margin:0 auto;
  display: flex;
  justify-content: center;
  align-items: stretch;
  li{
    margin-right: 0.5em;
    text-align: center;
    &:last-child{
      margin-right: 0;
    }
  }
  a{
    display: flex;
    align-items: center;
    justify-content: center;
    background: $main_color;
    font-size: 1.6rem;
    line-height:1;
    color: #fff;
    height: 100%;
    padding: 1em 1.5em;
    border:1px solid $main_color;
    @include mq(w1300){
      font-size: 1.5rem;
    }
    @include mq(sm-){
      &:hover{
        background: #fff;
        color: $main_color;
      }
    }
  }
  @include mq(sm){
    li{
      margin-right: 0.3em;
      a{
        font-size: 1.4rem;
        padding: 1em 0.5em;
      }
    }
  }
  @include mq(xs){
    padding: 4rem 0;
    flex-wrap: wrap;
    justify-content: space-between;
    li{
      width: 49%;
      margin-right: 0;
      &:nth-child(n+3){
        margin-top: 0.2em;
      }
    }
    a{
      padding: 0.5em;
    }
  }
}




/*--------------------------------------------
  form
--------------------------------------------*/

.c-form_box{
  $amp :&;
  padding:$margin_ll 0;
  @include mq(sm){
    padding:$margin_lg 0;
  }
  @include mq(xs){
    padding:$margin_md 0;
  }
  .input_short{
    width: 30%;
  }
  .input_middle{
    width: 60%;
  }
  .input_long{
    width: 80%;   
  }
  .form_input{
    margin-bottom: 1em;
    &:last-child{
      margin-bottom: 0;
    }
  }
  .annotation{
    font-size: 1.4rem;
    color: #333;
  }
  &__sheet{
    margin-bottom: $margin_md;
    tr{
      border-bottom:solid 1px #ddd;
      line-height: 1.4;
      &.-top{
        th,
        td{
          vertical-align: top;
        }
        #{$amp}__label{
          padding-top: .5em;
        }
      }
    }
    th,
    td{
      padding: 2em;
      vertical-align: middle;
      text-align: left;
      line-height: 1.6;
      font-weight: 400;
    }
    th{
      width: 25%;
      span{
        font-size: 0.8em;
        margin-left: 0.5em;
        color: $accent_color;
      }
    }
    td{
      width: 85%;
      .flex{
        display: flex;
        align-items: flex-end;
        span{
          margin-left: 0.5em;
        }
      }
      label{
        display: flex;
        align-items: center;
      }
      .h-adr{
        >div:nth-child(2){
          span{
            margin-top: 0;
          }
        }
      }
      >span{
        margin-top: 1em;
        margin-bottom: 0.5em;
        display: block;
        text-align: left;
        margin-left: 0!important;
        &.mgn{
          margin-left: 1em;
        }
        &.mgn02{
          margin-top: 1em;
        }
      }
    }
    @include mq(sm){
      .input_short{
        width: 50%;
      }
      .input_middle{
        width: 80%;
      }
      .input_long{
        width: 100%;   
      }
      // tr{
      //   display: block;
      //   padding: 1.75em 0;
      // }
      // th,
      // td{
      //   display: block;
      //   padding: 0;
      //   width: 100%;
      // }
      th{
        padding: 2em 0em;
        width: 28%;
        line-height: 1.4;
        margin-bottom: 1.5em;
      }
    }
    @include mq(xs){
      margin-bottom: 4rem;
      .input_long{
        display: block;
      }
      tr{
        display: block;
        padding: 1.75em 0;
      }
      td{
        border:none;
      }
      th,
      td{
        display: block;
        padding: 0.5em;
        width: 100%;
        text-align: center;
      }
      .input_short{
        width: 60%;
      }
      .input_middle{
        width: 100%;
      }
    }
  }
  &__label{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &.-require{
      &:after{
        content:"【必須】";
        color: $font_color;
        margin-left: .5em;
        @include mq(sm){
          @include mq(xs-){
            display: block;
            font-size: .875em;
            margin-left: 0;
          }
        }
      }
    }
    @include mq(md){
      @include mq(sm-){
        display: block;
        &.-require{
          &:after{
            display: block;
            margin-left: 0;
            text-indent:-.5em;
          }
        }
      }
    }
  }
  #{$amp}__error{
    margin-top: .5em;
    .error{
      color: $font_color;
      font-size: 1em;
      &:before{
        content:"※";
        margin-right: .25em;
      }
    }
  }
  &__submit{
    @include mq(sm-){
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &__btn{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row-reverse;
    @include mq(xs-){
      .c-btn_pl{
        width: 300px;
        &:nth-child(n+2){
          margin-right: $margin_ss;
        }
      }
    }
    @include mq(sm){
      justify-content: center;
      margin-top: $margin_sm;
      flex-direction: row;
    }
    @include mq(xs){
      flex-direction: row;
      .c-btn_pl{
        display: block;
        margin:0 auto;
        width: 48%;
        &:nth-child(n+2){
          margin-left: 4%;
          margin-right: 0;
        }
      }
    }
    @include mq(ss){
      display: block;
      .c-btn_pl{
        width: 100%;
        &:nth-child(n+2){
          margin-left: 0;
          margin-top: $margin_sm;
        }
      }
    }
  }
  &__p-box{
      background: #fff;
      padding: 3em;
      border:2px solid $sub_color;
    }
  &__agreement{
    max-width: 1000px;
    margin: 0 auto;
    a{
      text-decoration:underline;
      @include mq(sm-){
        &:hover{
          text-decoration: none;
        }
      }
    }
    #{$amp}__input{
      text-align: center;
      font-size: 1.8rem;
      margin-top: 1em;
      margin-bottom: 2em;
      input[type="checkbox"]{
        width: 1em;
        height: 1em;
      }
      &.-check{
        padding: 0;
        margin-right: .75em;
        .mwform-checkbox-field{
          display: block;
          margin-top: 0;
          &:not(:last-child){
            margin-right: 0;
          }
        }
        input[type="checkbox"]{
          + .mwform-checkbox-field-text{
            padding-left: 1.75em;
            font-size: 0;
          }
        }
      }
    }
    #{$amp}__error{
      @include mq(sm-){
        margin-top: 0;
        margin-left: 1em;
      }
      @include mq(sm-){
        width: 100%;
        margin-top: 1em;
        margin-left: 0;
      }
    }
  }
  &__input{
    textarea{
      height: 12em;
    }
    @include mq(sm-){
      &.-short{
        .text_field{
          width: 40%;
        }
      }
      &.-middle{
        .text_field{
          width: 60%;
        }
      }
      &.-long{
        .text_field{
          width: 80%;
        }
      }
    }
    &.-radio{
      line-height: 1;
      padding: .5em 0;
      @include mq(ss-){
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
      }
      .mwform-radio-field{
        display: block;
        margin-top: 1em;
        &:not(:last-child){
          margin-right:2.5em;
        }
        +.mwform-radio-field{
          margin-left: 0;
        }
      }
      input[type="radio"]{
        display: none;
        + .mwform-radio-field-text{
          display: block;
          position: relative;
          min-width: 1.6rem;
          min-height: 1.6rem;
          padding-left: 2.4rem;
          &:before,
          &:after{
            position: absolute;
            content:"";
            left: 0;
            top: 0;
            width: 1.6rem;
            height: 1.6rem;
            border-radius: 50%;
          }
          &:before{
            border:solid 1px #aaa;
          }
          &:after{
            background: $font_color;
            transform:scale(.35);
            visibility: hidden;
          }
        }
        &:checked{
          + .mwform-radio-field-text{
            &:after{
              visibility: visible;
            }
          }
        }
      }
    }
    &.-check{
      line-height: 1;
      padding: .5em 0;
      @include mq(ss-){
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
      }
      .mwform-checkbox-field{
        display: block;
        margin-top: 1.6rem;
        &:not(:last-child){
          margin-right:4rem;
        }
        ~ .mwform-checkbox-field{
          margin-left: 0;
        }
      }
      input[type="checkbox"]{
        display: none;
        + .mwform-checkbox-field-text{
          display: block;
          position: relative;
          min-width: 1.6rem;
          min-height: 1.6rem;
          padding-left: 2.4rem;
          &:before,
          &:after{
            position: absolute;
            content:"";
            left: 0;
            top: 0;
            width: 1.6rem;
            height: 1.6rem;
          }
          &:before{
            border:solid 2px #e0e0e0;
          }
          &:after{
            height: 1rem;
            width: 1.6rem;
            border-left: solid 3px $font_color;
            border-bottom: solid 3px $font_color;
            transform-origin: left bottom;
            transform:rotate(-45deg) translate(25%,75%);
            visibility: hidden;
          }
        }
        &:checked{
          + .mwform-checkbox-field-text{
            &:after{
              visibility: visible;
            }
          }
        }
      }
    }
  }
  .mw_wp_form_preview{
    #{$amp}__label{
      padding: 0;
    }
    #{$amp}__agreement{
      display: none;
    }
    #{$amp}__sheet{
      tr{
        &.-top{
          th,
          td{
            vertical-align: middle;
          }
          #{$amp}__label{
            padding-top: 0;
          }
        }
      }
    }
    #{$amp}__submit{
      justify-content: flex-end;
    }
  }
  &.-thanks{
    .c-btnwrap{
      margin-top: $margin_md;
    }
    @include mq(xs){
      .c-ttl_pl{
        font-size: 2rem;
      }
      .c-btnwrap{
        margin-top: $margin_sm;
        text-align: center;
      }
    }
  }
}
/*--------------------------------------------
  GOOGLE MAP
--------------------------------------------*/
.c-gmap {
  position: relative;
  padding-top: 56.25%;
  height: 0;
  overflow: hidden;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


/*--------------------------------------------
  OBJECT FIT
--------------------------------------------*/
.c-img_ofi {
  position: relative;
  padding-top: 60%;
  backface-visibility: hidden;

  .ofi {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;
    &.cover{
      object-fit: cover;
      object-position: center center;
      font-family: 'object-fit: cover;object-position:center center;';
    }

    &.contain {
      margin: auto;
      object-fit: contain;
      font-family: 'object-fit: contain;object-position:center center;';
    }
  }
}


/*--------------------------------------------
  OTHERS
--------------------------------------------*/
.c-img_full {
  max-width: none;
  height: auto;
}

.-c-center_block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.-c-ta_left {
  text-align: left;
}

.-c-ta_center {
  text-align: center;
}

.-c-ta_right {
  text-align: right;
}

.c-ta_justify{
  @include mq(ss-){
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.c-pe_none {
  pointer-events: none;
}


.c-flex{
  display: flex;
  justify-content: flex-start;
  align-items:stretch;
  flex-wrap: wrap;
}

@include mq(sm-) {
  .c-pe_sp {
    pointer-events: none;
    cursor: default;
  }
}