@charset "utf-8";
/*--------------------------------------------
  BREADCRUMBS
--------------------------------------------*/
.l-page{

}
.l-content{

}
.l-main{
  &.-under{
    @include mq(xs){
    }
  }
}
.l-breadcrumb{
  line-height: 1;
  border-top:1px solid #ddd;
  border-bottom:1px solid #ddd;
  padding: 1em 0;
  font-size: 1.4rem;;
  &__list{
    justify-content: flex-start;
    align-items: center;
    li{
      position: relative;
      padding-right: 1.8125em;
      margin-right: .5625em;
      &:after{
        position: absolute;
        content:"";
        right: 0;
        top:50%;
        width: 1em;
        height: 1px;
        background:$font_color;
        transform: rotate(-60deg);
      }
      a{
        position: relative;
        display: block;
        padding:5px 0;
        max-width: 30em;
        text-overflow:ellipsis;
        overflow: hidden;
        white-space: nowrap;
        &:after{
          position: absolute;
          content:"";
          right: 0;
          left: initial;
          bottom:0;
          width: 0%;
          height: 1px;
          background: $font_color;
          transition:$transition_val;
        }
        &:hover{
          &:after{
            left: 0;
            right: initial;
            width: 100%;
          }
        }
      }
      &:last-child{
        padding-right:0;
        margin-right: 0;
        &:after{
          display: none;
        }
        a{
          pointer-events: none;
          &:after{
            display: none;
          }
        }
      }
    }
  }
}